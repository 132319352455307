<template>
  <transition name="fade" appear>
    <div>
      <el-row class="home">
        <el-col :span="4" class="left">
          <template v-for="(item, index) in typeList">
            <div
              :class="[index == typeIndex ? 'type_item_true' : 'type_item_false']"
              :key="index"
              @click="change(index)"
            >
              {{ item.syllabusTypeName }}
            </div>
          </template>
        </el-col>
        <el-col :span="20" class="right">
          <section class="teach_list">
            <div class="show" v-show="!show"><el-empty description="暂无数据"></el-empty></div>

            <template v-for="(item, index) in classList">
              <div class="nav_item" :key="index" @click="goDetail(item)">
                <div class="navItem_img hoverBox1">
                  <el-image :src="item.logo" fit="fill" style="height: 100%; width: 100%">
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline"></i>
                    </div>
                  </el-image>
                </div>
                <div class="navItem_text hoverText">
                  {{ item.syllabusName }}
                </div>
              </div>
            </template>
          </section>

          <el-pagination
            v-show="show"
            background=""
            layout="prev, pager, next"
            :total="total"
            :page-size="pageSize"
            @current-change="handleCurrentChange"
            :current-page.sync="currPage"
          >
          </el-pagination>
        </el-col>
      </el-row>
    </div>
  </transition>
</template>

<script>
import { CHANGE_NAVINDEX } from "../../store/mutation-types";
export default {
  data() {
    return {
      keyWords: "",
      //数据总量
      total: 0,
      //单页数量
      pageSize: 9,
      //当前页数
      currPage: 1,
      //分类下标
      typeIndex: 0,
      //分类
      typeList: [],
      //课程
      classList: [],
      //是否有数据
      show: false,
      userInfo:{},
    };
  },
  methods: {
    // 修改当前页
    handleCurrentChange(newCurr) {
      this.currPage = newCurr;
      this.getClassList();
    },
    //获取教学大纲分类
    getTypeList() {
      var that = this;
      var getData = {};
      var condition = {};
      let a = sessionStorage.getItem("userInfo")
      if(a==null){
        condition.promptCode = "rz"
      }else{
        let promptCode = JSON.parse(sessionStorage.getItem("userInfo")).promptCode; 
        if(promptCode == "kj" || promptCode == "KJ"){
          condition.promptCode = "kj"
        }else if(promptCode == "rz" || promptCode == "RZ"){
          condition.promptCode = "rz"
        }else{
          return
        }
      }
      getData.condition = condition;
      getData.pageSize = 100;
      getData.currPage = this.currPage;
      this.$api.home.getPublicList(getData).then((res) => {
        if (res.data.code === 200) {
          this.typeList = res.data.data.data;
          this.getClassList();
        }
      });
    },
    //获取教学大纲列表
    getClassList() {
      var that = this;
      var getData = {};
      var condition = {};
      condition.syllabusTypeCode = this.typeList[this.typeIndex].syllabusTypeCode;
      getData.condition = condition;
      getData.pageSize = 100;
      getData.currPage = this.currPage;
      this.$api.home.getSyllabusList(getData).then((res) => {
        if (res.data.code === 200) {
          this.classList = res.data.data.data;
          this.total = res.data.data.count;
          if (this.total > 0) {
            this.show = true;
          } else {
            this.show = false;
          }
        }
      });
    },

    //切换分类
    change(index) {
      this.typeIndex = index;
      this.getClassList();
    },
    //查看详情
    goDetail(item) {
      let a = item.syllabusCode

     //当前时间
      var currentTime = Date.parse(new Date());
      console.log('this.userInfo',this.userInfo);
      if (this.userInfo!=null&&this.userInfo.expirationTime) {
        //此处待开放
        if (currentTime - this.userInfo.expirationTime >= 0) {
          // this.open();
           this.$message({
            type: "error",
            message: "您的课程已过期，请前往个人中心重新激活后使用",
            offset:'400',
          });
        } else {
         this.$router.push({
            path: "/teachDetail",
            query: {
              syllabusCode: item.syllabusCode,
            },
          });
      }
      } else {
        // this.open();
          this.$message({
            type: "error",
            message: "您未登录或您的课程暂未激活，请前往个人中心激活后使用",
            offset:'400',
          });
      }

    },
  },

  created() {
    this.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    this.$store.commit(CHANGE_NAVINDEX, "0");
    this.typeIndex = this.$route.query.index;
    this.getTypeList();
  },
};
</script>

<style lang="less" scoped>
.hoverBox1{
  cursor: pointer;
}
.home {
  width: 1140px;
  height: 650px;
  .left {
    background-color: #eae3d8;
    height: 100%;
    overflow: auto;
    .type_item_true {
      font-size: 20px;
      color: white;
      padding: 25px 0;
      background-color: #fab300;
    }
    .type_item_true{
      cursor: pointer;
    }
     .type_item_false{
      cursor: pointer;
    }
    .type_item_false {
      font-size: 20px;
      color: black;
      padding: 25px 0;
    }
  }
  .right {
    background-color: #f2f1f1;
    height: 100%;
    .teach_list {
      width: 100%;
      display: flex;
      min-height: 600px;
      // padding: 0 51px;
      justify-content: flex-start;
      align-content: flex-start;
      background-color: #f2f1f1;
      flex-flow: wrap;
      .show {
        height: 600px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
      }
      .nav_item {
        display: flex;
        width: 33%;
        margin: 21px 0 0 0;
        flex-direction: column;
        align-items: center;
        .navItem_img {
          height: 139px;
          width: 139px;
          border-radius: 6px;
          overflow: hidden;
        }

        .navItem_text {
          margin-top: 15px;
          font-size: 14px;
          font-weight: 600;
          color: #000000ff;
        }
      }
    }
  }
}
</style>
